class Utilities {
    constructor() {}

    definePatternHeight() {
        var height = document.body.clientHeight;
        var bodyHeight = height;
        $('.pattern-overlay').css('height', bodyHeight);
    }

    setEqualHeight(element) {
        var maxContentHeight = 0;
        $(element).each(function () {
            if ($(this).height() > maxContentHeight) {
                maxContentHeight = $(this).height();
            }
        });

        $(element).height(maxContentHeight);
    }

    navbarScroll() {
        $(function () {
            var $header = $('.header-container');
            var $headerTop = $('.header-nav-top');
            var $body = $('body');

            var $logo = $('.header-container .company-brand img');
            var $logoHeight = $logo.height();

            $(document).scroll(function () {
                $header.toggleClass('scrolled', $(this).scrollTop() > $header.height());

                if ($header.hasClass('scrolled')) {
                    $('.header-nav-top').hide(300);
                    $logo.css('height', $logoHeight * 0.8);
                } else {
                    $('.header-nav-top').show(300);
                    $logo.css('height', $logoHeight);
                }
            });

            if ($body.hasClass('homepage')) {
                $body.css('margin-top', $header.height());
            } else {
                $body.css('margin-top', $header.height());
            }

            if ($('body').hasClass('homepage') && window.matchMedia("(min-width: 980px)").matches) {
                $(window).scroll(function () {
                    if ($(document).scrollTop() > 50) {
                        let top = $(document).scrollTop();
                        let alpha = (top - 100) / 200;
                        if (alpha > 1.00) alpha = 1.00;
                        $(".header-container").css("background-color", "rgba(27, 38, 46, " + alpha + ")");
                    } else {
                        $(".header-container").css("background-color", "transparent");
                    }
                });
            }
        });
    }

    scrollToTop() {
        $('html, body').animate({
            scrollTop: 0
        }, 800);
    }

    defineBackToTop() {
        $(document).on('scroll', function () {
            // back to top button on scroll
            var s = $(window).scrollTop(),
                d = $(document).height(),
                c = $(window).height();

            var scrollPercent = Math.trunc((s / (d - c)) * 100);

            // If Scroll is > 50 show back_to_top btn,
            if (scrollPercent >= 50) {
                setTimeout(function () {
                    $('#back_to_top').removeClass('animate-out').addClass('animate-in');
                }, 500)
            }

            // if scroll is < 10 hide back_to_top btn
            if (scrollPercent <= 10) {
                setTimeout(function () {
                    $('#back_to_top').removeClass('animate-in').addClass('animate-out');
                }, 500)
            }
        });
    }

    bgSrcset(imgEl) {
        $.each(imgEl, function (index, el) {
            $.each($(el).data(), function (i, e) {
                if (i.split('-')[0] == 'img') {
                    let breakpoint = i.split('-')[1];
                    if ($(window).width() < breakpoint) {
                        $(el).css('background-image', 'url(' + $(el).data(i) + ')');
                    }
                }
            });
        });
    }
}

export default Utilities;